import React, { useState } from "react";
import { Image, Nav, Navbar, Button } from "react-bootstrap";
import "./TopPage.scss";
import "./RespTopPage.scss";
import { tour_manager_login } from "../../utilities/env.js";
import { NavLink } from "react-router-dom";
import SlidePane from "../slidepane/SlidePane";
import { BiMenuAltRight } from "react-icons/bi";

function TopPage(props) {
  const [slidePaneOpen, setSlidePaneOpen] = useState(false);

  return (
	<>
		<div className="test-container">
			<div className="navbar-trans">
				<Navbar expand="xl">
				<Navbar.Brand href="/">
					<Image
						className="otma-logo otma-logo-change"
						src={require("../../images/visiblelogo.png")}
					/>            
				</Navbar.Brand>
				<a
					href="#"
					className="mobile-slidepane-trigger"
					onClick={() => setSlidePaneOpen(true)}
				>
					<BiMenuAltRight />
				</a>

				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="navbar-expanded">
					<NavLink
						to="/"
						className="nav_text"
						activeClassName="active"
						exact
					>
						HOME
					</NavLink>
					<NavLink
						to="/about"
						className="nav_text"
						activeClassName="active"
						exact
					>
						ABOUT US
					</NavLink>
					<NavLink
						to="/tourmanager"
						className="nav_text"
						activeClassName="active"
						path={["/tourmanager", "/tourmanager/:id"]}
					>
						TOUR MANAGERS
					</NavLink>
					<NavLink
						to="/news"
						className="nav_text"
						activeClassName="active"
						path={["/news", "/news/details"]}
					>
						NEWS & ARTICLES
					</NavLink>
					<NavLink
						to="/gallery"
						className="nav_text"
						activeClassName="active"
						path={["/gallery", "/gallery/:id"]}
					>
						PHOTO GALLERY
					</NavLink>
					<a
						href="https://www.facebook.com/OTMA-Charity-Page-275839729187354/"
						className="nav_text"
					>
						CHARITY
					</a>
					<NavLink
						to="/contactUs"
						className="nav_text"
						activeClassName="active"
						exact
					>
						CONTACT US
					</NavLink>
					<div className="otma-actions">
						<Button className="apply-button register" href="/register">
						Register Now
						</Button>
						<Button
						className="apply-button login"
						href={tour_manager_login}
						>
						Member Sign in
						</Button>
						{/* <Nav.Link className="text-white apply-button pl-4" variant="primary" href={tour_manager_login}>Portal Login</Nav.Link> */}
					</div>
					</Nav>
				</Navbar.Collapse>
				</Navbar>
			</div>
			
			{window.innerWidth < 1200 && (
				<SlidePane
				setSlidePaneOpen={(e) => setSlidePaneOpen(e)}
				slidePaneOpen={slidePaneOpen}
				/>
			)}
		</div>
		{/* <img className="cny_banner" src={require("../../images/home/vesak-banner.jpeg")} /> */}
	</>
    
  );
}

export default TopPage;
